import React, { useState, useEffect } from 'react';
import { PushNotificationPreview } from "push-notification-preview";
import './App.css';

const sendSendRequest = async (title, message, link, tokens) => {
    return fetch('https://finnleys.eu.wizzbit.net/app/sendPushMessage.php', {
        method: 'POST',
        body: JSON.stringify({
            title: title,
            message: message,
            tokens: tokens,
            link: link
        }),
    });

    // return new Promise((resolve, reject) => {
    //    setTimeout(() => {
    //        resolve({
    //            status: '200',
    //            sent: selectedUsers
    //        })
    //    }, 1000);
    // });
};

const fetchUsers = async () => {

    const response = await fetch('https://finnleys.eu.wizzbit.net/app/get_users.php');
    const users = await response.json();

    return users.map(u => ({
        id: u.ID,
        naam: u.display_name,
        roles: u.roles,
        push_token: u.push_token,
        platform: u.platform
    }));

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const users = [
                { id:1, naam: 'Fabian Lachman', group: 'Distributeurs' },
                { id:2, naam: 'Dennis', group: 'Distributeurs' },
                { id:3, naam: 'Peter', group: 'Leveranciers' },
            ];
            resolve(users);
        }, 1000);
    });
};

function App() {
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [link, setLink] = useState("");
    const [sending, setSending] = useState(false);
    const [result, setResult] = useState('');
    const [users, setUsers] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const users = await fetchUsers();
            setUsers(users);
        }
        if (!users) {
            fetchData();
        }
    }, []);

    if (!users) {
        return 'Loading...';
    }

    const groupsFlattened = users.map(u => u.roles).flat();

    const groups = groupsFlattened.reduce((groups, group) => {
        if (groups.indexOf(group) === -1) {
            return [...groups, group]
        }
        return groups;
    }, []);

    const startSend = async () => {
        setSending(true);
        const result = await sendSendRequest(
            title,
            message,
            link,
            users
                .filter(u => selectedUsers.indexOf(u.id) !== -1 && u.push_token !== '')
                .map(u => u.push_token));
        setSending(false);
        // setTitle('');
        // setMessage('');
        // setLink('');
        const resultJson = await result.json();
        setResult(`Verzonden naar ${resultJson.filter(r => r.status === 'ok').length} personen`);
    };

  return (
    <div className="App" style={{ width: '480px', padding: '20px' }}>

        <div style={{ textAlign: 'left' }}>
            <b>Push bericht opstellen</b><br/>
            <br/>
            Titel:<br/>
            <input type="text" value={title} onChange={(e) => { setTitle(e.target.value); setResult('')}} /><br/>
            <br/>
            Bericht: <br/>
            <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} /><br/>
            <br/>
            Link naar product of pagina (optioneel):<br/>
            <input type="text" placeholder={"https://finnleys.eu/producten/bi-magic/"} value={link} onChange={(e) => setLink(e.target.value)} />
            <br/>
            <br/>
            <br/>
            Voorbeeld:
            <PushNotificationPreview
                platform="apple" // or "android"
                appName="Finnley's"
                time="nu"
                title={title}
                message={message}
            />
            <br/>
            <br/>
            <br/>
            Verstuur naar:<br/>
            <br/>
            <table>
                <tbody>
                    <tr>
                        <th>
                            <input type="checkbox" onChange={(e) => {
                                setSelectedUsers(e.target.checked
                                    ? users.map(u => u.id)
                                    : []
                                );
                            }} checked={selectedUsers.length === users.length}/>
                        </th>
                        <th>Naam</th>
                        <th>Groep</th>
                        <th>Device</th>
                        {/*<th>Push Token</th>*/}
                    </tr>
                    {users.map(u => (
                        <tr key={u.id}>
                            <td><input type="checkbox" checked={selectedUsers.indexOf(u.id) !== -1} onChange={(e) => {
                                if (e.target.checked) { setSelectedUsers((prev) => [...prev, u.id]) }
                                else {
                                    setSelectedUsers((prev) => [...prev.filter(pu => pu !== u.id)])
                                }
                            }} /></td>
                            <td>{u.naam}</td>
                            <td>{u.roles.join(', ')}</td>
                            <td>{u.platform}</td>
                            {/*<td>{u.push_token}</td>*/}
                        </tr>
                    ))}
                </tbody>
            </table>


            Selecteer:
            {groups.map(g => (
                <button key={g} onClick={() => {
                    setSelectedUsers((prev) => [...prev, ...users.filter(u => u.roles.indexOf(g) !== -1).map(u => u.id)])
                }}>{g}</button>
            ))}
            <button onClick={() => setSelectedUsers([])}>Geen</button>
                <br/><br/>


            <button onClick={startSend} disabled={sending || !title || !message || selectedUsers.length === 0}>Verstuur push bericht</button><br/>
            <br/>
            {result ? result : ''}
        </div>

    </div>
  );
}

export default App;
